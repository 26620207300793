import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { moveToCompanyPage, toTitleCase } from '../../actions/helper';
import "./grid.css";

const Grid = (props) => {
  const navigate = useNavigate();

  return (
    <div className="grid-container" style={{marginRight: "20px"}}>
      {/* <div className="heading" style={{ backgroundColor: "#020b26", color: "white", display: "flex", alignItems: "flex-start", justifyContent: "center", marginLeft: "0px", width: "100%", padding: "10px" }}>
        <span style={{fontSize: "0.8rem" }}>{props.gridHeading}</span>
      </div> */}
      <div className="upper-grid">
        {props.upperGrid.map((item, index) => (
          <div className="grid-item" key={index}>
            {item.value ? <h2 style={{ 
                  color: "#020b26" }}>{item.value}</h2> : <Skeleton />}
            <p>{item.caption}</p>
          </div>
        ))}
      </div>
      <div className="lower-grid">
        {props.lowerGrid.map((item, index) => (
          <div className="grid-item" key={index}>
            {item.value ? (
              <h2 style={{ 
              color: "#020b26" }}>
                {item.caption === "Patents Granted"
                  ? item.value + "%"
                  : item.value}
              </h2>
            ) : (
              <Skeleton />
            )}
            <p>{item.caption}</p>
          </div>
        ))}
      </div>
      {(props.topPlayer)?
      <div className="lastGrid" style={{width:"100%"}}>
        <div className="grid-item" style={{width: "100%", height: "auto"}} >
        {(props.topPlayer)?
            <h2 style={{textDecoration: "underline", cursor: "pointer",  color: "#020b26"  }} onClick={() => {moveToCompanyPage(navigate,props.topPlayer)}}>{toTitleCase(props.topPlayer)}</h2>
            :
            <Skeleton/>
            }
        <p>Top organization</p>
        </div>
      </div>
      :
      <div></div>
      }
    </div>

  )
};

export default Grid;

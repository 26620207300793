import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { moveToCompanyPage, toTitleCase } from "../../actions/helper";
import { sanitizeText } from "../../actions/helper";
import SimpleMap from "../../components/WorldMap/WorldMap";
import Grid from "../../components/Grid/grid";
import "./technologyExplore.css";
import ISOCode from "../../actions/countryISOcodes.json";
import LineChart from "../../components/Charts/lineChart";
import { Tracking } from "../../components/tracking/tracking";
import { useNavigate } from "react-router-dom";
import { Carousel } from "../../components/carousel/carousel";
import HorizontalBarChart from "../../components/horizontalBarChart/horizontalBarChart";

const TechnologyGlobal = (props) => {
  const navigate = useNavigate();

  const technologyOverview = props.technologyOverview;
  const topYear = props.topYear;
  const topYearCount = props.topYearCount;
  const grantRate = props.grantRate;
  const [topCountryInfo, setTopCountryInfo] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [searchStatus, setSearchStatus] = useState(false);
  const [keyPlayers, setKeyPlayers] = useState([]);


  const analyticsAPI = "https://api.incubig.org/analytics/technology-analysis";

  const [selectedCountryInfo, setSelectedCountryInfo] = useState();

  useEffect(() => {
    filterByGeography("US");
    setSelectedCountry("US");
    setKeyPlayers(props.keyPlayers);
    setTopCountryInfo(props.topCountryInfo);  
  }, [props]);

  const filterByGeography = (country) => {
    setSelectedCountryInfo();
    setSelectedCountry(country);
    if (props.selectedTechnology) {
      axios
        .get(analyticsAPI, {
          headers: {
            "x-api-key":
              "60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
          },
          params: { cpc: props.selectedTechnology.cpc, country: country },
        })
        .then((response) => {
          setSelectedCountryInfo(response.data);
        });
    }
  };


  return (
    <div>
      <Tracking
        title={
          props.selectedTechnology
            ? props.selectedTechnology.definition
            : "Technology analysis by Incubig"
        }
        description={
          "Patents in " +
          (props.selectedTechnology
            ? props.selectedTechnology.definition
            : "Technology analysis by Incubig")
        }
        keywords={
          (props.selectedTechnology&&props.selectedTechnology.definition!=null)
            ? props.selectedTechnology.definition.split(" ").join(",")
            : "Technology analysis by Incubig"
        }
      />
      <Row className="g-0">
        <Col md="6" className="borderRight">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              View global activity
            </span>
          </div>
          {technologyOverview ? (
            <p className="para" style={{ marginTop: "10px" }}>
              Leading global research hubs include{" "}
              <strong style={{ color: "black" }}>
                {technologyOverview.country_analysis.top_countries[0].country},{" "}
                {technologyOverview.country_analysis.top_countries.length > 1
                  ? " followed by " +
                    technologyOverview.country_analysis.top_countries[1].country
                  : ""}
                {technologyOverview.country_analysis.top_countries.length > 2
                  ? " and " +
                    technologyOverview.country_analysis.top_countries[2].country
                  : " "}
              </strong>
              . Noteworthy patent filers like{" "}
              <b
                style={{ cursor: "pointer" }}
                onClick={() => {
                  moveToCompanyPage(
                    navigate,
                    technologyOverview.assignee_analysis.top_assignees[0]
                      .assignee
                  );
                }}
              >
                {toTitleCase(
                  technologyOverview.assignee_analysis.top_assignees[0].assignee
                )}
              </b>{" "}
              drive innovation worldwide.
            </p>
          ) : (
            <Skeleton />
          )}
          {technologyOverview ? (
            <div className="trendText" style={{ marginTop: "10px" }}>
              <ul className="keyPointsUL">
                <li style={{ marginBottom: "10px" }}>
                  {typeof technologyOverview !== "undefined" ? (
                    <div className="keyPointsLI">
                      <b className="keyPointsLI">
                        {
                          technologyOverview.country_analysis.top_countries[0]
                            .country
                        }
                      </b>{" "}
                      is leading the research{" "}
                      {technologyOverview.country_analysis.top_countries
                        .length > 1
                        ? " followed by " +
                          technologyOverview.country_analysis.top_countries[1]
                            .country
                        : ""}
                      {technologyOverview.country_analysis.top_countries
                        .length > 2
                        ? " and " +
                          technologyOverview.country_analysis.top_countries[2]
                            .country
                        : " "}
                      .
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li style={{ marginBottom: "10px" }}>
                  {typeof technologyOverview !== "undefined" ? (
                    <div className="keyPointsLI">
                      <b
                        className="keyPointsLI"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          moveToCompanyPage(
                            navigate,
                            technologyOverview.assignee_analysis
                              .top_assignees[0].assignee
                          );
                        }}
                      >
                        {toTitleCase(
                          technologyOverview.assignee_analysis.top_assignees[0]
                            .assignee
                        )}
                      </b>{" "}
                      is the top patent filer, with{" "}
                      <b className="keyPointsLI">
                        {
                          technologyOverview.assignee_analysis.top_assignees[0]
                            .count
                        }
                        .
                      </b>
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                </li>
              </ul>
            </div>
          ) : (
            <div className="trendText">
              <Skeleton />
            </div>
          )}

          <div className="horizontalBarChart">
            <HorizontalBarChart keyGeographies={(technologyOverview)?technologyOverview.country_analysis.top_countries.map(item=>[item.country,item.count]).slice(0,5):undefined} heading="Top five countries,based on number of organizations"/>
          </div>

          <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black" }}
          >
            <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
              Click country to see activity
            </span>
          </div>
          <div className="citationMap">
            <SimpleMap
              color="blue"
              backgroundColor="white"
              borderColor="grey"
              clickHandler={(e) => {
                filterByGeography(e);
              }}
              data={
                technologyOverview
                  ? technologyOverview.country_analysis.top_countries.map(
                      (item) => {
                        return [item.country, item.count];
                      }
                    )
                  : []
              }
              // label="distribution of organizations in different countries"
            />
          </div>
        </Col>
        <Col md="6">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
              Global activity under the technology
            </span>
          </div>
          <div className="box-container" style={{ marginLeft: "25px" }}>
            <div className="box" style={{ borderRight: "1px solid black" }}>
              <div className="widget-block">
                {typeof technologyOverview !== "undefined" ? (
                  <div className="value">
                    {technologyOverview.country_analysis.total_countries}
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Countries
                </p>
              </div>
            </div>
            <div className="box" style={{ borderRight: "1px solid black" }}>
              <div className="widget-block">
                {typeof technologyOverview !== "undefined" ? (
                  <div className="value">
                    {technologyOverview.assignee_analysis.total_assignees}
                  </div>
                ) : (

                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Organizations
                </p>
              </div>
            </div>
            <div className="box">
              <div className="widget-block">
                {technologyOverview ? (
                  <div className="value">
                    {technologyOverview.inventor_analysis.total_inventors}
                  </div>
                ) : (
                  <Skeleton />
                )}
                <p className="body-3" style={{ fontSize: "0.8rem" }}>
                  Inventors
                </p>
              </div>
            </div>
          </div>
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "30px",
            }}
          >
            <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
              Country specific activity under technology
            </span>
          </div>
          <div style={{ marginLeft: "27px" }}>
            <Grid
              gridHeading={
                selectedCountryInfo ? ISOCode[selectedCountry] : "...loading..."
              }
              upperGrid={[
                {
                  value: selectedCountryInfo
                    ? selectedCountryInfo.country_analysis.total_countries
                    : undefined,
                  caption: "Countries",
                },
                {
                  value: selectedCountryInfo
                    ? selectedCountryInfo.assignee_analysis.total_assignees
                    : undefined,
                  caption: "Organizations",
                },
                {
                  value: selectedCountryInfo
                    ? selectedCountryInfo.inventor_analysis.total_inventors
                    : undefined,
                  caption: "Inventors",
                },
              ]}
              lowerGrid={[
                {
                  value: selectedCountryInfo
                    ? selectedCountryInfo.application_trends.reduce(
                        (prev, current) =>
                          prev.count > current.count ? prev : current
                      ).year
                    : undefined,
                  caption: "Top year",
                },
                {
                  value: selectedCountryInfo
                    ? selectedCountryInfo.application_trends.reduce(
                        (prev, current) =>
                          prev.count > current.count ? prev : current
                      ).count
                    : undefined,
                  caption: "Patents in top year",
                },
                {
                  value: selectedCountryInfo
                    ? (
                        (selectedCountryInfo.status[0].Active * 100) /
                        selectedCountryInfo.total_applications
                      ).toFixed(1)
                    : undefined,
                  caption: "Patents Granted",
                },
              ]}
              topPlayer={
                selectedCountryInfo
                  ? selectedCountryInfo.assignee_analysis.top_assignees[0]
                      .assignee
                  : undefined
              }
            />
          </div>
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "30px",
            }}
          >
            <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
              Top active organisations (
              {selectedCountryInfo
                ? ISOCode[selectedCountryInfo.country_analysis.top_countries[0].country]
                : "...loading..."}
              ) 
            </span>
          </div>

          <Table className="mainTable globalTable">
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{
                    fontWeight: "normal",
                    marginLeft: "-2%",
                    backgroundColor: "#020b26",
                    color: "white",
                  }}
                >
                  Organization
                </th>

                <th
                  className="text-center"
                  style={{
                    fontWeight: "normal",
                    backgroundColor: "#020b26",
                    color: "white",
                  }}
                >
                  Recent Patents
                </th>
              </tr>
            </thead>
            {typeof selectedCountryInfo != "undefined" ? (
              <tbody>
                {selectedCountryInfo.assignee_analysis.top_assignees
                  .slice(0, 5)
                  .map((row, index) => (
                    <tr
                      className="rowTable"
                      key={index}
                      onClick={() => {
                        moveToCompanyPage(navigate, row.assignee);
                      }}
                    >
                      <td className="tableFirstColumn">
                        {sanitizeText(row.assignee)}
                      </td>
                      <td className="tableSecondColumn">{row.count}</td>
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody>
                <tr className="rowTable">
                  <td className="tableFirstColumn">
                    <Skeleton width={200} />
                  </td>
                  <td className="tableSecondColumn">
                    <Skeleton width={150} />
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Col>

        <hr
          className="thin-horizontal-line"
          style={{
            backgroundColor: "rgb(199, 198, 198)",
            color: "rgb(199, 198, 198)",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />

        <div
          className="summaryTag"
          style={{ width: "auto", backgroundColor: "white", color: "black" }}
        >
          <span
            className="summaryTagText"
            style={{ fontSize: "0.8rem", fontWeight: "bold" }}
          >
            See other active organizations
          </span>
        </div>
        <div
          className="cpcTable"
          style={{ marginLeft: "0px", marginTop: "0px" }}
        >
          {technologyOverview ? (
            <Carousel
              data={technologyOverview.assignee_analysis.top_assignees.map(
                (item) => {
                  return [item.assignee, item.count];
                }
              )}
              isMoving={true}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </Row>

      <hr className="thin-horizontal-line" />

      <Row className="g-0" style={{ marginBottom: "50px" }}>
        <Col xs="12" md="6" className="borderRight">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginLeft: "20px",
              marginTop: "0px",
            }}
          >
            <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
              Selected Country:{" "}
              <b style={{ fontSize: "0.8rem" }}>
                {selectedCountryInfo
                  ? ISOCode[selectedCountry]
                  : "...loading..."}
              </b>
            </span>
          </div>
          {selectedCountryInfo ? (
            <div
              className="trendText"
              style={{ marginTop: "30px", height: "auto", marginLeft: "25px" }}
            >
              {selectedCountryInfo.total_applications} patents are filed from{" "}
              {selectedCountry},{" "}
              {toTitleCase(
                selectedCountryInfo.city_analysis.top_cities[0].city
              )}{" "}
              is the top city, which filed maximum patent
              {selectedCountryInfo.city_analysis.top_cities.length > 1
                ? ", followed by " +
                  toTitleCase(
                    selectedCountryInfo.city_analysis.top_cities[1].city
                  )
                : "."}
              .
            </div>
          ) : (
            <div className="trendText" style={{ marginTop: "30px" }}>
              <Skeleton />
            </div>
          )}
          {selectedCountryInfo ? (
            <div className="researchAreaChart">
              <LineChart
                labels={selectedCountryInfo.application_trends.map((item) => {
                  return item.year;
                })}
                series={selectedCountryInfo.application_trends.map((item) => {
                  return item.count;
                })}
                name={"Patents filed"}
                title={"YoY patent filing trends"}
              />
            </div>
          ) : (
            <Skeleton count={2} />
          )}
        </Col>
        <Col xs="12" md="6">
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginLeft: "20px",
              marginTop: "50px",
            }}
          >
            <span className="summaryTagText" style={{ fontSize: "0.8rem" }}>
              Top Locations
            </span>
          </div>
          {selectedCountryInfo ? (
            <ol>
              {selectedCountryInfo.city_analysis.top_cities.map(
                (item, index) => (
                  <li key={index} className="country-list-item">
                    <p className="country-name">{toTitleCase(item.city)}</p>
                    <span className="patents-count">{item.count} patents</span>
                  </li>
                )
              )}
            </ol>
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TechnologyGlobal;

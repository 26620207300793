import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navbar, Container, Col, Button, Row } from "reactstrap";
import { FaBars } from "react-icons/fa";
import {
  moveToCompanyPage,
  moveToForYouPage,
  moveToResultsPage,
  moveToSignInPage,
  moveToTechnologyPage,
} from "../../actions/helper";
import "./personalizedHeader.css";
import { FaCaretDown } from "react-icons/fa";
import axios from "axios";

export const PersonalizedHeader = ({ hideButton }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const authorizationAPI = "https://api.incubig.org/authorization/authorize";

  useEffect(() => {
    let authToken = localStorage.getItem("token");
    if (!authToken) {
      setIsLoggedIn(false);
    } else {
      // Verify the auth token
      axios
        .get(authorizationAPI, { headers: { "auth-token": authToken } })
        .then((response) => {
          if (response.status === 200) {
            // Auth token is valid, user is authenticated
            setIsLoggedIn(true);
          } else {
            // Auth token is not valid, user not authenticated
            setIsLoggedIn(false);
            logOut();
          }
        });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigateToPage = (buttonName) => {
    switch (buttonName) {
      case "Monitor":
        moveToCompanyPage(navigate);
        break;
      case "Technology":
        moveToTechnologyPage(navigate, "overview");
        break;
      case "Research":
        moveToResultsPage(
          navigate,
          localStorage.getItem("query")
            ? localStorage.getItem("query")
            : "query optimization"
        );
        break;
      case "ForYou":
        moveToForYouPage(navigate);
        break;
      default:
        break;
    }
  };

  const redirectToHome = () => {
    navigate("/");
  };

  const moveToSignUp = () => {
    navigate("/sign-up");
  };

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const location = useLocation();

  const isLandingPage = location.pathname === "/";
  const isSignup = location.pathname === "/sign-up";
  const isSignIn = location.pathname === "/sign-in";

  return (
    <Navbar
      className={`brandLine ${isLandingPage ? "landingPageHeader" : ""}`}
      expand="md"
    >
      <Container fluid>
        <Row>
          <Col md="6">
            <a
              className={`logoText ${
                isSignup || isSignIn ? "whiteText" : ""
              }`}
              onClick={redirectToHome}
              style={{ cursor: "pointer" }}
            >
              Incubig AI
            </a>
          </Col>
          <Col md="6" className="d-flex justify-content-end">
            {/* Personalized Header Buttons */}
            {/* <div className={`profileSide ${menuOpen ? "menu-open" : ""}`}>
              {(windowWidth > 766 || menuOpen) && (
                <>
                  <div
                    className={`companyButton ${
                      window.location.pathname.includes("company-page")
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => navigate("Monitor")}
                  >
                    Monitor Competition
                  </div>
                  <div
                    className={`technologyButton ${
                      window.location.pathname.includes("technology-page")
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => navigate("Technology")}
                  >
                    Know Your Technology
                  </div>
                  {isLoggedIn && (
                    <div
                      className={`foryouButton ${
                        window.location.pathname.includes("for-you")
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => navigate("ForYou")}
                    >
                      For You
                    </div>
                  )}
                </>
              )}
              <div className="d-flex">
                {isLoggedIn ? (
                  <div onClick={toggleDropdown} className="userProfile">
                    <img
                      src={process.env.PUBLIC_URL + "/profile.jpg"}
                      className="rounded-circle"
                      style={{ width: "30px", height: "30px" }}
                      alt="My Image"
                    />
                    <div
                      className={`user ${
                        
                        window.location.pathname === "/sign-up" ||
                        window.location.pathname === "/sign-in"
                          ? "whiteUserText"
                          : "blackUserText"
                      }`}
                      style={{
                        fontSize: "0.8rem",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        width: "auto",
                      }}
                    >
                      {localStorage.getItem("user")}
                    </div>
                    <div
                      className={`caret ${
                        
                        window.location.pathname === "/sign-up" ||
                        window.location.pathname === "/sign-in"
                          ? "whiteUserText"
                          : "blackUserText"
                      }`}
                      style={{
                        marginLeft: "0px",
                        cursor: "pointer",
                        transform: dropdownVisible
                          ? "rotate(180deg)"
                          : "rotate(0)",
                        transition: "transform 0.3s ease",
                      }}
                      onClick={toggleDropdown}
                    >
                      <FaCaretDown />
                    </div>
                    {dropdownVisible && (
                      <div
                        style={{
                          position: "absolute",
                          top: "125%",
                          right: "0%",
                          backgroundColor: "#020b26",
                          color: "white",
                          border: "1px solid rgb(204, 204, 204)",
                          borderRadius: "5px",
                          padding: "5px",
                          zIndex: "1",
                        }}
                      >
                        <div
                          onClick={logOut}
                          style={{ fontSize: "0.8rem", padding: "5px" }}
                          className="logOut"
                        >
                          Logout
                        </div>
                      </div>
                    )}
                  </div>
                ) : !hideButton ? (
                  <Button
                    className="personalize"
                    onClick={() => navigate("/sign-up")}
                    style={{ position: "relative", top: "0px" }}
                  >
                    Unlock
                  </Button>
                ) : (
                  <p
                    className={`whiteText ${
                      hideButton ? "signupText" : "loginText"
                    }`}
                    onClick={() =>
                      navigate(hideButton ? "/sign-in" : "/sign-up")
                    }
                  >
                    {hideButton ? "Sign In" : "Sign Up"}
                  </p>
                )}
              </div>
            </div>

            {window.location.pathname !== "/" && (
              <div
                className={`menu ${
                  
                  window.location.pathname === "/sign-up" ||
                  window.location.pathname === "/sign-in"
                    ? "whiteBurger"
                    : "blackBurger"
                }`}
                onClick={toggleMenu}
              >
                <FaBars />
              </div>
            )} */}


{/* For loggin functionality  */}

<div>
 <br/>
 &nbsp;
</div>

{/* <div className="d-flex">
                {isLoggedIn ? (
                  <div onClick={toggleDropdown} className="userProfile">
                    <img
                      src={process.env.PUBLIC_URL + "/profile.jpg"}
                      className="rounded-circle"
                      style={{ width: "30px", height: "30px" }}
                      alt="My Image"
                    />
                    <div
                      className={`user ${
                        
                        window.location.pathname === "/sign-up" ||
                        window.location.pathname === "/sign-in"
                          ? "whiteUserText"
                          : "blackUserText"
                      }`}
                      style={{
                        fontSize: "0.8rem",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        width: "auto",
                      }}
                    >
                      {localStorage.getItem("user")}
                    </div>
                    <div
                      className={`caret ${
                        
                        window.location.pathname === "/sign-up" ||
                        window.location.pathname === "/sign-in"
                          ? "whiteUserText"
                          : "blackUserText"
                      }`}
                      style={{
                        marginLeft: "0px",
                        cursor: "pointer",
                        transform: dropdownVisible
                          ? "rotate(180deg)"
                          : "rotate(0)",
                        transition: "transform 0.3s ease",
                      }}
                      onClick={toggleDropdown}
                    >
                      <FaCaretDown />
                    </div>
                    {dropdownVisible && (
                      <div
                        style={{
                          position: "absolute",
                          top: "125%",
                          right: "0%",
                          backgroundColor: "#020b26",
                          color: "white",
                          border: "1px solid rgb(204, 204, 204)",
                          borderRadius: "5px",
                          padding: "5px",
                          zIndex: "1",
                        }}
                      >
                        <div
                          onClick={logOut}
                          style={{ fontSize: "0.8rem", padding: "5px" }}
                          className="logOut"
                        >
                          Logout
                        </div>
                      </div>
                    )}
                  </div>
                ) : !hideButton ? (
                  <Button
                    className="personalize"
                    onClick={() => navigate("/sign-up")}
                    style={{ position: "relative", top: "0px" }}
                  >
                    Unlock
                  </Button>
                ) : (
                  <p
                    className={`whiteText ${
                      hideButton ? "signupText" : "loginText"
                    }`}
                    onClick={() =>
                      navigate(hideButton ? "/sign-in" : "/sign-up")
                    }
                  >
                    {hideButton ? "Sign In" : "Sign Up"}
                  </p>
                )}
              </div> */}
{/* <Button
                    className="personalize"
                    onClick={() => navigate("/sign-up")}
                    style={{ position: "relative", top: "0px", marginRight: "0px" }}
                  >
                    Unlock
                  </Button> */}
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

import React, { useState, useEffect } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { moveToPatentPage, toTitleCase, truncateText } from "../../actions/helper";
import "./patentList.css";
import axios from "axios";

const PatentList = (props) => {
  const navigate = useNavigate();
  const itemsPerPage = 3;
  const patentData = props.keyApplications;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = patentData ? Math.ceil(patentData.length / itemsPerPage) : 0;
  const [displayedPatents, setDisplayedPatents] = useState(
    props.keyApplications ? props.keyApplications.slice(startIndex, endIndex) : undefined
  );
  const downloadApplicationsURL = "https://api.incubig.org/download/patents"

  useEffect(() => {
    setDisplayedPatents(
      props.keyApplications ? props.keyApplications.slice(startIndex, endIndex) : undefined
    );
  }, [props.keyApplications, startIndex]);

  const isLastPage = currentPage === totalPages;

  // Popover states
  const [downloadPopoverShow, setDownloadPopoverShow] = useState(false);
  const [loginPopoverShow, setLoginPopoverShow] = useState(false);

  // Popover content
  const downloadPopover = (
    <Popover id="download-popover">
      <Popover.Body>Downloading...</Popover.Body>
    </Popover>
  );

  const loginPopover = (
    <Popover id="login-popover">
      <Popover.Body>Please log in to download.</Popover.Body>
    </Popover>
  );

  const handleDownload = () => {
    const isLoggedIn = localStorage.getItem("token") !== null;
    if (isLoggedIn) {
      // Show download popover
      setDownloadPopoverShow(true);
      axios.get(downloadApplicationsURL,
        {headers:{
          "x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
          'Content-Disposition': "attachment; filename=assignee.xlsx",
        },
        responseType:"arraybuffer",
        params:props.downloadOption.params}  
      ).then((response)=>{
        console.log(response.headers.get('Content-Disposition'))
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", props.downloadOption.params[Object.keys(props.downloadOption.params)[0]]+".xlsx");
        document.body.appendChild(link);
        link.click();
        setDownloadPopoverShow(false)
      })
    } else {
      // Show login popover
      setLoginPopoverShow(true);
    }
  };

  return (
    <div className="patent-list forYouPatentList">
      {typeof displayedPatents !== "undefined" ? (
        <div>
          {displayedPatents.map((patent, index) => (
            <div
              key={index}
              className="patents-section"
              onClick={() => {
                moveToPatentPage(navigate, patent.publication_number, "about");
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="patentsContent">
                <div className="headingText">
                  {patent.title
                    ? truncateText(toTitleCase(patent.title), 80)
                    : truncateText("", 0)}
                </div>
                <h2 className="details-patent">
                  {patent.assignee
                    ? patent.assignee[0]
                      ? toTitleCase(patent.assignee[0])
                      : toTitleCase(patent.inventor[0])
                    : patent.name
                    ? toTitleCase(patent.name)
                    : toTitleCase(patent.inventor)}{" "}
                  | {toTitleCase(patent.industry)} |{" "}
                  {patent.application_year
                    ? patent.application_year
                    : String(patent.application_date).slice(0, 4)} |{" "}
                  {patent.assignee_country ? patent.assignee_country : patent.country}
                </h2>
                <p className="patentDescription">
                  {patent.abstract
                    ? truncateText(patent.abstract, 180)
                    : truncateText(patent.abstract_text, 180)}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="patents-section">
            <Skeleton />
          </div>
        </div>
      )}
      {patentData && totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={`paginationPage ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
          {(isLastPage && props.downloadOption && props.downloadOption.show) &&(
            <div className="downloadListButton">
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={downloadPopover}
                show={downloadPopoverShow}
                onHide={() => setDownloadPopoverShow(false)}
              >
                <div onClick={handleDownload}>Download All</div>
              </OverlayTrigger>
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={loginPopover}
                show={loginPopoverShow}
                onHide={() => setLoginPopoverShow(false)}
              >
                <div onClick={handleDownload}></div>
              </OverlayTrigger>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PatentList;

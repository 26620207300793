import React, {useState} from 'react';
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";
import { Tracking } from '../../components/tracking/tracking';
import PatentList from "../../components/patentsList/patentList";
import { removeDuplicatePatents, sanitizeText } from "../../actions/helper";
import { PatentTableFilters } from "../../components/patentTableFilters/patentTableFilters";
import Grid from '../../components/Grid/grid';
import ISOCode  from "../../actions/countryISOcodes.json";

const TechnologyDataset = (props) => {
    const technologyOverview = props.technologyOverview;
    const topYear = props.topYear
    const topYearCount = props.topYearCount
    const grantRate = props.grantRate
    const [selectedSortingLogic, setSelectedSortingLogic] = useState("Relevant");
    const [selectedCountry, setSelectedCountry] = useState(
        props.keyGeographies && props.keyGeographies.length === 1
          ? props.keyGeographies[0][0]
          : "All countries"
      );

      const handleSortingLogicChange = (e) => {
        // console.log(e.target.value)
        setSelectedCountry("All countries");
        if (e.target.value === "Relevant") {
          props.searchFunction(props.query, null, false);
        } else {
          props.searchFunction(props.query, null, true);
        }
        setSelectedSortingLogic(e.target.value);
      };

  return (
    <div>
      <Tracking  title={(props.selectedTechnology&&props.selectedTechnology.definition!=null)?props.selectedTechnology.definition:"Technology analysis by Incubig"} description={"Patents in "+((props.selectedTechnology)?props.selectedTechnology.definition:"Technology analysis by Incubig")} keywords={((props.selectedTechnology&&props.selectedTechnology.definition!=null)?props.selectedTechnology.definition.split(" ").join(","):"Technology analysis by Incubig")}/>
      <Row className="g-0">
        <Col xs="12" md="7"
          className="borderRight"
        >
           <div
            className="summaryTag"
            style={{ width: "auto", backgroundColor: "white", color: "black", marginTop: "0px"}}
          >
            <span className="summaryTagText" style={{fontSize: "0.8rem", fontWeight: "bold"}}>
              Patent data 
            </span>
          </div>
          {technologyOverview ? (
            <p className="para" style={{ marginTop: "10px" }}>
              In {technologyOverview.applications[0]["application_date"].slice(0,4)},{" "}
              {sanitizeText(technologyOverview.applications[0].name)}
              {" "}filed a patent on{" "}
              {sanitizeText(technologyOverview.applications[0].title)}.
            </p>
          ) : (
            <p className="para" style={{ marginTop: "10px" }}>
              <Skeleton />
            </p>
          )}
          {technologyOverview 
          ? 
          (
            <PatentTableFilters
              keyGeographies={technologyOverview.country_analysis.top_countries}
              filterFunction={props.filterFunction}
            />
          ) 
          : 
          ( 
            <p className="para" style={{ marginTop: "10px" }}>
              <Skeleton />
            </p>
          )}
          <PatentList
            keyApplications={
              technologyOverview ? removeDuplicatePatents(technologyOverview.applications) : []
            }
            downloadOption={{show:true,params:{'cpc':(props.selectedTechnology)?props.selectedTechnology.cpc:"H04L 1/00"}}}
          />
        </Col>
        <Col className="xs=12" md="5">
        <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Technology Summary{" "}
              {selectedCountry != "All countries"
                ? " in " + ISOCode[selectedCountry] + " "
                : ""}{" "}
            </span>
          </div>
          <div className="forStats">
            
            <Grid
              gridHeading="Summary"
              upperGrid={[
                {
                  value: technologyOverview
                    ? technologyOverview.country_analysis.total_countries
                    : undefined,
                  caption: "Countries",
                },
                {
                  value: technologyOverview
                    ? technologyOverview.assignee_analysis.total_assignees
                    : undefined,
                  caption: "Organizations",
                },
                {
                  value: technologyOverview
                    ? technologyOverview.inventor_analysis.total_inventors
                    : undefined,
                  caption: "Inventors",
                },
              ]}
              lowerGrid={[
                {
                  value: technologyOverview
                    ? technologyOverview.country_analysis.top_countries[0]
                        .country
                    : undefined,
                  caption: "Leading country",
                },
                { value: topYearCount, caption: "Patents in " + topYear },
                { value: grantRate, caption: "Patents Granted" },
              ]}
              topYear={topYear}
              topPlayer={
                technologyOverview
                  ? technologyOverview.assignee_analysis.top_assignees[0]
                      .assignee
                  : undefined
              }
            />
            </div>
        </Col>
        </Row>
    </div>
  )
}

export default TechnologyDataset;

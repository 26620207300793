import React from 'react'
import { useState } from 'react'
import ISOCode from '../../actions/countryISOcodes.json'

export const PatentTableFilters = (props) => {
  const geographicFilterOptions = (props.keyGeographies)?[["All countries"]].concat(props.keyGeographies):["All countries"]
  const [selectedGeography,setSelectedGeography] = useState(props.keyGeographies&&props.keyGeographies.length===1?props.keyGeographies[0].country:"All countries")
  const sortingOptions = ["Recent","Relevant"]
  const [selectedSortingOption,setSelectedSortingOption] = useState("Relevance")

  const changeSortingLogic = (e) => {
    setSelectedSortingOption(e.target.value)
  }

  const changeSelectedGeography = (e) => {
    setSelectedGeography(e.target.value)
    if (e.target.value==="All countries"){
      props.filterFunction(null)
    }
    else{
      props.filterFunction(e.target.value)
    }
    
  }

  const getGeography = (geo) => {
    if (typeof geo === "object") {
      if (geo.country){
        return geo.country
      }
      else {
        return geo[0]
      }
    }
    else {
      return geo
    }
    
  }

  return(
    <div className="d-flex justify-content-between">
      <div
        className="headline-3 d-flex "
        style={{
          fontSize: "0.7rem",
          marginLeft: "25px",
          marginTop: "30px",
        }}
      >
        Click to read patents
      </div>

      <div className="patentAction">
        {/* <div>
          <select value={selectedSortingOption} onChange={(e)=>{changeSortingLogic(e)}} style={{fontSize: "0.7rem", height: "26px", marginRight: "10px"}}>
          {sortingOptions.map((sortingOption) => (
            <option key={sortingOption} value={sortingOption} style={{fontSize: "0.7rem"}}>
              {sortingOption}
            </option>
          ))}
          </select>
        </div> */}
        <div>
          <select value={selectedGeography} onChange={(e)=>{changeSelectedGeography(e)}} style={{fontSize: "0.7rem", height: "26px", marginRight: "10px"}}>
          {geographicFilterOptions.map((geography) => (
            <option key={getGeography(geography)} value={getGeography(geography)} style={{fontSize: "0.7rem"}}>
              {(getGeography(geography)!=="All countries")?ISOCode[getGeography(geography)]:getGeography(geography)}
            </option>
          ))}
          </select>
        </div>
        
      </div>
    </div>
  )
}
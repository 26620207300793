import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Tracking } from "../../components/tracking/tracking";
import PatentList from "../../components/patentsList/patentList";
import Skeleton from "react-loading-skeleton";
import { removeDuplicatePatents, truncateText } from "../../actions/helper";
import "../../pages/forYouPage/forYou.css";
import "../../pages/searchResults/searchResults.css";
import "../../components/ourCustomers/customer.css";
import PatentcardInsight from "./patentcardInsight";
import PatentWordCloud from "../forYouTechnologies/wordCloud";
import LineChart from "../../components/Charts/lineChart";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";
import { LiaCalendarWeekSolid } from "react-icons/lia";

const ForYouStateArt = (props) => {
  const researchAreas = props.researchAreas;
  const technologyStatistics = props.technologyStatistics;
  const searchHistory = removeDuplicatePatents(
    props.applicationsFromSearchHistory
  );

  const applicationsAPI = "https://api.incubig.org/analytics/applications";

  const [selectedResearchArea, setSelectedResearchArea] = useState(0);
  const [filteredApplications, setFilteredApplications] = useState(
    props.keyApplications
  );

  const formatToSingleDecimal = (value) => {
    if (value === null || value === undefined) return null;
    return parseFloat(value.toFixed(1));
  };
  const getTrendIcon = (value) => {
    if (value > 0) {
      return <FaArrowTrendUp style={{ color: "green" }} />;
    } else if (value < 0) {
      return <FaArrowTrendDown style={{ color: "red" }} />;
    }
    return null;
  };

  useEffect(() => {
    setFilteredApplications();
    if (selectedResearchArea) {
      axios
        .post(applicationsAPI, [researchAreas[selectedResearchArea].cpc], {
          headers: {
            "x-api-key":
              "60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4",
          },
        })
        .then((response) => {
          setFilteredApplications(response.data);
        });
    }
  }, [selectedResearchArea, props]);

  const handleTechnologyChange = (e) => {
    setSelectedResearchArea(parseInt(e.target.value));
    // setTechnologyStatistics()
    props.updateTechnologyStatisticsFunction(researchAreas[e.target.value].cpc);
  };

  return (
    <div>
      <Tracking
        title={"State of the art patents"}
        description={"State of the art patents"}
        keywords={"State of the art patents"}
      />

      <div className="userOverview" style={{ marginTop: "0px" }}>
        <div
          className="summaryTag"
          style={{
            width: "auto",
            backgroundColor: "white",
            color: "black",
            marginTop: "0px",
            marginLeft: "0px",
          }}
        >
          <span
            className="summaryTagText"
            style={{
              fontSize: "0.8rem",
              fontWeight: "bolder",
              marginLeft: "24px",
            }}
          >
            Patent Insights
          </span>
        </div>

        <div
          style={{
            margin: "0px 0px",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <label htmlFor="technologyDropdown" className="labelTechnology">
            Select Technology:
          </label>

          {researchAreas.length > 0 && (
            <select
              id="technologyDropdown"
              className="selectTechnology"
              value={selectedResearchArea}
              onChange={handleTechnologyChange}
              style={{
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                cursor: "pointer",
                width: "100%",
                maxWidth: "300px",
                boxSizing: "border-box",
              }}
            >
              {researchAreas.map((technology, index) => (
                <option key={index} value={index}>
                  {truncateText(technology.definition, 50)}
                </option>
              ))}
            </select>
          )}
          <div
            className="technology-name"
            style={{
              marginTop: "20px",
              marginLeft: "15px",
              fontWeight: "bold",
              fontSize: "1.3rem",
            }}
          >
            {researchAreas[selectedResearchArea]?.definition || <Skeleton />}
          </div>
        </div>

        <Row className="g-0">
          <Col xs="12" md="3">
            <div
              className="overviewBlock"
              style={{
                marginRight: "20px",
                marginLeft: "30px",
                border: "1px solid #ddd",
              }}
            >
              <p>Patents filed this week</p>
              <br />
              <p style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
                {technologyStatistics ? (
                  technologyStatistics.growth.applications_this_week
                ) : (
                  <Skeleton />
                )}
              </p>

              <br />
              <div className="credits">
                <div className="creditBlock1">
                  <p>
                    {technologyStatistics ? (
                      getTrendIcon(technologyStatistics.growth.weekly_growth)
                    ) : (
                      <Skeleton />
                    )}{" "}
                    <span
                      style={{
                        color:
                          technologyStatistics?.growth.weekly_growth > 0
                            ? "green"
                            : "red",
                      }}
                    >
                      {technologyStatistics ? (
                        formatToSingleDecimal(
                          technologyStatistics.growth.weekly_growth
                        )
                      ) : (
                        <Skeleton />
                      )}
                      %
                    </span>{" "}
                    from last week
                  </p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>
                <div className="creditBlock2">
                  <p></p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>
              </div>
            </div>
            <div
              className="overviewBlock"
              style={{
                marginRight: "20px",
                marginLeft: "30px",
                border: "1px solid #ddd",
              }}
            >
              <p>Patents filed this month </p>
              <br />
              <p style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
                {technologyStatistics ? (
                  technologyStatistics.growth.application_this_month
                ) : (
                  <Skeleton />
                )}
              </p>
              <br />
              <br />
              <div className="credits">
                <div className="creditBlock1">
                  <p>
                    {technologyStatistics ? (
                      getTrendIcon(technologyStatistics.growth.monthly_growth)
                    ) : (
                      <Skeleton />
                    )}{" "}
                    <span
                      style={{
                        color:
                          technologyStatistics?.growth.monthly_growth > 0
                            ? "green"
                            : "red",
                      }}
                    >
                      {technologyStatistics &&
                      technologyStatistics.growth.monthly_growth ? (
                        formatToSingleDecimal(
                          technologyStatistics.growth.monthly_growth
                        )
                      ) : (
                        <Skeleton />
                      )}
                      %
                    </span>
                    from last month
                  </p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>

                <div className="creditBlock2">
                  <p></p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" md="3">
            <div
              className="overviewBlock"
              style={{
                marginRight: "20px",
                marginLeft: "30px",
                border: "1px solid #ddd",
              }}
            >
              <p>Average grant time</p>
              <br />
              <p style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
                {technologyStatistics ? (
                  formatToSingleDecimal(
                    technologyStatistics.grant_rate_analysis
                      .current_avg_grant_time
                  )
                ) : (
                  <Skeleton />
                )}
                &nbsp; days
              </p>

              <br />
              <div className="credits">
                <div className="creditBlock1">
                  <p>
                    {technologyStatistics ? (
                      getTrendIcon(
                        technologyStatistics.grant_rate_analysis
                          .change_in_grant_time
                      )
                    ) : (
                      <Skeleton />
                    )}{" "}
                    <span
                      style={{
                        color:
                          technologyStatistics?.grant_rate_analysis
                            .change_in_grant_time > 0
                            ? "green"
                            : "red",
                      }}
                    >
                      {technologyStatistics ? (
                        formatToSingleDecimal(
                          technologyStatistics.grant_rate_analysis
                            .change_in_grant_time
                        )
                      ) : (
                        <Skeleton />
                      )}
                      %
                    </span>
                    &nbsp; from historical average
                  </p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>
                <div className="creditBlock2">
                  <p></p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>
              </div>
            </div>
            <div
              className="overviewBlock"
              style={{
                marginRight: "20px",
                marginLeft: "30px",
                border: "1px solid #ddd",
              }}
            >
              <p>Grant Rate</p>
              <br />
              <p style={{ fontWeight: "bold", fontSize: "2.5rem" }}>
                {technologyStatistics ? (
                  formatToSingleDecimal(
                    technologyStatistics.grant_rate_analysis.current_grant_rate
                  )
                ) : (
                  <Skeleton />
                )}{" "}
                %
              </p>
              <br />
              <br />
              <div className="credits">
                <div className="creditBlock1">
                  <p>
                    {" "}
                    {technologyStatistics ? (
                      getTrendIcon(
                        technologyStatistics.grant_rate_analysis
                          .change_in_grant_rate
                      )
                    ) : (
                      <Skeleton />
                    )}{" "}
                    <span
                      style={{
                        color:
                          technologyStatistics?.grant_rate_analysis
                            .change_in_grant_rate > 0
                            ? "green"
                            : "red",
                      }}
                    >
                      {technologyStatistics ? (
                        formatToSingleDecimal(
                          technologyStatistics.grant_rate_analysis
                            .change_in_grant_rate
                        )
                      ) : (
                        <Skeleton />
                      )}
                      %
                    </span>{" "}
                    from historical average
                  </p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>
                <div className="creditBlock2">
                  <p></p>
                  <br />
                  <span style={{ fontWeight: "bold" }}></span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div
              className="overviewBlock"
              style={{
                marginRight: "20px",
                marginLeft: "30px",
                border: "1px solid #ddd",
              }}
            >
              {technologyStatistics && (
                <LineChart
                  labels={technologyStatistics.trends.map((item) => {
                    return item.date;
                  })}
                  series={technologyStatistics.trends.map((item) => {
                    return item.count;
                  })}
                />
              )}
            </div>
          </Col>
        </Row>

        <hr className="thin-horizontal-line" />

        <div
          className="summaryTag newPatentTag"
          style={{
            width: "auto",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <span
            className="summaryTagText"
            style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
          >
            New Patents This Week in -
            <span style={{ textAlign: "center", marginLeft: "5px" }}>
              {researchAreas[selectedResearchArea]?.definition || <Skeleton />}
            </span>
          </span>
        </div>
        {filteredApplications ? (
          <div className="scrollable-containerInsight">
            {filteredApplications.map((patent, index) => (
              <div key={index} style={{ position: "relative" }}>
                <PatentcardInsight
                  patent={patent}
                  showBackgroundColor={false}
                />
                <div
                  className="dateTag"
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "40px",
                    backgroundColor: "rgb(73 83 196)",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  <span style={{ fontSize: "0.7rem", color: "white" }}>
                    {patent["publication_date"]}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          props.keyApplications && (
            <div className="scrollable-containerInsight">
              {props.keyApplications.map((patent, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <PatentcardInsight
                    patent={patent}
                    showBackgroundColor={false}
                  />
                  <div
                    className="dateTag"
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "40px",
                      backgroundColor: "rgb(73 83 196)",
                      padding: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    <span style={{ fontSize: "0.7rem", color: "white" }}>
                      {patent["publication_date"]}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )
        )}

        {/* <hr className="thin-horizontal-line" /> */}

        {/* <div
          className="summaryTag stateArtTag"
          style={{
            backgroundColor: "white",
            color: "black",
            marginTop: "10px",
            position: "relative",
          }}
        >
          <span
            className="summaryTagText"
            style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
          >
            Relevant patents according to your research area
          </span>

          <div className="dropdownContainer">
            <label
              htmlFor="researchAreaDropdown"
              style={{
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "0.8rem",
              }}
            >
              Select Research Area:{" "}
            </label>
            <select
              id="researchAreaDropdown"
              value={selectedResearchArea}
              onChange={(e) => setSelectedResearchArea(e.target.value)}
              className="dropdownInsight"
            >
              {researchAreas.map((area) => (
                <option key={area.cpc} value={area.cpc}>
                  {area.definition}
                </option>
              ))}
            </select>
          </div>
        </div> */}

        {/* <Row className="g-0">
          <Col xs="12" md="12" className="borderRight stateArtRow">
            <div>
              {filteredApplications && filteredApplications.length > 0 ? (
                <PatentList
                  keyApplications={filteredApplications}
                  downloadOption={{
                    show: true,
                    params: { query: localStorage.getItem("query") },
                  }}
                />
              ) : (
                <div className="customSkeletonContainer">
                  <Skeleton count={5} className="customSkeleton" />
                </div>
              )}
            </div>
          </Col>
          <Col xs="12" md="5">
          
          <div >
          <div
            className="summaryTag"
            style={{
              width: "auto",
              backgroundColor: "white",
              color: "black",
              marginTop: "0px",
            }}
          >
            <span
              className="summaryTagText"
              style={{ fontSize: "0.8rem", fontWeight: "bold" }}
            >
              Patents Insights
            </span>
          </div>
      <PatentWordCloud />
    </div>
          </Col>
        </Row> */}

        {/* <hr className="thin-horizontal-line" />

        <div
          className="summaryTag"
          style={{
            width: "auto",
            backgroundColor: "white",
            color: "black",
            marginTop: "10px",
          }}
        >
          <span
            className="summaryTagText"
            style={{ fontSize: "0.8rem", fontWeight: "bolder" }}
          >
            Patents based on your search history
          </span>
        </div>

        <div className="scrollable-containerInsight">
          {searchHistory &&
            searchHistory.map((patent, index) => (
              <PatentcardInsight key={index} patent={patent} />
            ))}
        </div> */}

        <div style={{ textAlign: "center", position: "relative" }}>
          <hr className="belowForYou" />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "0.75rem",
              height: "20px",
              fontWeight: "bold",
            }}
          >
            Scroll to explore all
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "0.75rem",
              marginBottom: "40px",
              height: "40px",
            }}
          >
            System-generated, personalized patent lists.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForYouStateArt;

import axios from "axios";
import React, {useState, useEffect} from 'react'
import { Row, Col } from "reactstrap";
import { PersonalizedHeader } from "../../components/personalizedHeader/personalizedHeader";
import ForYouHeader from "../../sections/forYouHeader/forYouHeader";
import { logOutUser, removeDuplicates, toTitleCase } from "../../actions/helper";
import ForYouStateArt from "../../sections/forYouStateArt/forYouStateArt";
import ForYouTechnologies from "../../sections/forYouTechnologies/forYouTechnologies";
import ForYouReport from "../../sections/forYouReport/forYouReport";
import ForYouOrganizations from "../../sections/forYouOrganizations/forYouOrganizations";
import OwnYourInvention from "../../sections/forYouReport/forms/ownyourinvention";
import ForYouStatus from "../../sections/forYouStatus/forYouStatus";
import GoToSignin from "../../components/goToSignin/goToSignin";
import Skeleton from "react-loading-skeleton";
import { Footer } from "../../components/footer/footer";
import ForYouProfile from "../../sections/forYouProfile/forYouProfile";
import { set } from "react-ga";
import { jwtDecode } from "jwt-decode";

const ForYou = () => {
  const [selectedSection,setSelectedSection] = useState(window.location.pathname.split("/").at(-1))
  const [researchAreas, setResearchAreas] = useState([])
  const [keyApplications, setKeyApplications] = useState()
  const [applicationsFromSearchHistory, setApplicationsFromSearchHistory] = useState([])
  const [inventorsApplications,setInventorsApplications] = useState([])
  const [technologySummary, setTechnologySummary] = useState()
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [keyPlayers, setKeyPlayers] = useState([]);
  const [keyPlayer, setKeyPlayer] = useState();
  const [searchStatus, setSearchStatus] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userActivity, setUserActivity] = useState([]);
  const [IDFs, setIDFs] = useState([]);
  const [technologyStatistics, setTechnologyStatistics] = useState()

  const userDomain = localStorage.getItem("email")?localStorage.getItem("email").split("@").at(-1):undefined

  useEffect(()=>{
    setSelectedSection(window.location.pathname.split("/").at(-1))
  },[window.location.pathname])

  const authenticationAPI =
    "https://api.incubig.org/authorization/authorize";
  const applicationsAPI = 
    "https://api.incubig.org/analytics/applications"
  const analyticsAPI = 
    "https://api.incubig.org/analytics/technology-analysis";
  const cpcDefinitionAPI = 
    "https://api.incubig.org/analytics/cpc-definition";
  const inventorAPI =
  "https://api.incubig.org/analytics/inventor";
  const trackingAPI =
  "https://api.incubig.org/authorization/track/usage";
  const updateProfileAPI =
  "https://api.incubig.org/authorization/users/";
  const getIDFurl = 
  "https://api.incubig.org/solutions/idf"
  const xSearchAPI =
  "https://hiykgsfdzd.execute-api.us-east-1.amazonaws.com/production/xsearch"
  const technologyStatisticsAPI =
  "https://api.incubig.org/analytics/technology-statistics"

  useEffect(() => {
    const authToken = localStorage.getItem("token");
    
    if (!authToken) {
      // No authToken present - user is not logged in
      setAuthenticated(false);
      setLoading(false);
    } 
    else {
      // Verify the auth token
      axios.get(authenticationAPI,{headers:{"auth-token":authToken}}).then((response) => {
        if (response.data.status_code === 200) {
          // Auth token is valid, user is authenticated
          setAuthenticated(true);
          setUserInfo(response.data.data);
          getResearchAreas(authToken)
          getIDFs()
          getInventorsApplications(response.data.data.name)
        } 
        else {
        // Auth token is not valid, user not authenticated
        setAuthenticated(false);
        logOutUser()
        }
      })
      .catch((error) => {
      // Error occurred during authentication check
      setAuthenticated(false);
      console.error("Error while checking authentication:", error);
      });
      }
  }, []);

  const getTechnologies = (cpcs) => {
    let tempTechnologySummary = []
    getTechnologyStatistics(cpcs[0].cpc)
    cpcs.forEach(async (cpc) => {
      await axios.get(analyticsAPI,{headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},params:{cpc:cpc.cpc}}).then((response)=>{
        let density = response.data.total_applications
        let grantRate = 0
        response.data.status.forEach((item) => {
          if (Object.keys(item)[0] === "Active") {
            grantRate =
              (
                (item["Active"] * 100) /
                density
              ).toFixed(1)
          }
        });
        let bestYear = response.data.application_trends.toSorted((a,b)=>b.count-a.count).at(0)
        
        tempTechnologySummary.push({"cpc":cpc.cpc,"definition":cpc.definition,"density":density,"grantRate":grantRate,"bestYear":bestYear,"data":response.data})  
      })
    })
    setTechnologySummary(tempTechnologySummary)
  }

  const getTechnologyStatistics = (cpc) => {
    axios.get(technologyStatisticsAPI,{headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},params:{"cpc":cpc}}).then((response)=>{
      setTechnologyStatistics(response.data)
    })
  }

  const getInventorsApplications = (user) => {
    axios.get(inventorAPI,{headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"},
      params:{inventor:user}}).then((response)=>{
        try{
          setInventorsApplications(JSON.parse(response.data).applications)
        }
        catch{
          setInventorsApplications(response.data.applications)
        }
      })
  }

  const getResearchAreas = (authToken) => {
    axios.get(trackingAPI,{headers:{"auth-token":authToken}}).then((response)=>{  
      let orderedResearchAreas = []  
      if (response.status === 200) {
        if (response.data.data.length>0) {
          orderedResearchAreas = Object.entries(response.data.data[0]["research_areas"]).sort(([,a],[,b]) => b-a).slice(0,5)
          
          if (orderedResearchAreas.length>0) {
            axios.post(applicationsAPI,[orderedResearchAreas[0][0]],{headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"}}).then((response)=>{
              setKeyApplications(response.data)
            });
            axios.post(cpcDefinitionAPI, orderedResearchAreas.map((item)=>{return item[0]})).then((resp) => {
              let tempResearchAreas = resp.data.map((item,index)=>{return {"cpc":orderedResearchAreas[index][0],"definition":item.definition,"count":orderedResearchAreas[index][1]}})
              setResearchAreas(tempResearchAreas)
              getTechnologies(tempResearchAreas)
            });
          }
          getApplicationsFromSearchHistory(response.data.data[0]["search_history"][0])
          setUserActivity({"searches":response.data.data[0]["search_history"],"likes":response.data.data[0]["liked_patents"]})
        }
      }
      else {
        orderedResearchAreas = [{cpc:"G06T 7/00",definition:"Image Analysis"},{cpc:"A61B 5/00", definition:"Measuring for diagnostic purposes"},{cpc:"H10N 60/00", definition:"Superconductors"}]
        setResearchAreas(orderedResearchAreas)
      }
    });
  }

  const getApplications = (cpc) => {
    axios.post(applicationsAPI,[cpc],{headers: {"x-api-key":"60PKCZgn3smuESHN9e8vbVHxiXVS/8H+vXeFC4ruW1d0YAc1UczQlTQ/C2JlnwlEOKjtnLB0N2I0oheAHJGZeB2bVURMQRC1GvM0k45kyrSmiK98bPPlJPu8q1N/TlK4"}}).then((response)=>{
      return response.data
    })
  }

  const setState = (data, latestFirst = false, filter) => {
    if (data.count !== 0) {
      setSearchStatus(true);
    } else {
      setKeyPlayers(removeDuplicates(data.facets.assignee));
      if (data.facets.assignee.length > 0) {
        setKeyPlayer(data.facets.assignee[0][0]);
        localStorage.setItem("assignee", data.facets.assignee[0][0]);
      }
    }
  };
  
  const clearState = () => {
    setKeyPlayers();
  };
  
  const updateUserInfo = (field,value) => {
    let tempUserInfo = userInfo
    const authToken = localStorage.getItem("token");
    const config = {
      method: 'put',
      url:updateProfileAPI+"1?field="+field+"&value="+value,
      headers: {
        "auth-token": authToken,
      },
    }
    axios.request(config).then((response)=>{
      if (response.status === 200) {
        tempUserInfo[field] = value
        setUserInfo(tempUserInfo)
      }
    })
  }

  const getIDFs = () => {
    let token = localStorage.getItem("token");
    if (token) {
      let userEmail = jwtDecode(token).user.email;
      axios.get(getIDFurl, {
        headers: {
          "auth-token": token
        },
        params:{
          "email":userEmail
        }
      })
      .then((response) => {
        setIDFs(response.data.data)
      })
    }
  }

  const getApplicationsFromSearchHistory = (query) => {
    let tempApplications = []
    axios.get(xSearchAPI,{params:{"query":query}}).then((response)=>{
      setApplicationsFromSearchHistory(response.data.results)
    })
  }

  
  
  return (
    <div className="forYouPage">
      <Row className="g-0">
          <Col xs="12">
              <PersonalizedHeader/>
          </Col>
      </Row>
      <ForYouHeader changeSection={setSelectedSection} reportsSection={true} patentsSection={(userDomain==="umn.edu" | userDomain==="iitism.ac.in")?true:false}/>

      {/* Page banner */}
      <Row className="g-0">
        <Col xs="12">
          <hr className="thin-horizontal-line" />
          <span
            className="headingSearch"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.75rem",
              background: "linear-gradient(to right, #1834dd, #d079df)",
              color: "white",
              height: "40px",
            }}
          >
            For You &#124; {toTitleCase(window.location.pathname.split("/").at(-1)).replaceAll("-"," ")}
            
          </span>
        </Col>
      </Row>

      <hr
         className="thin-horizontal-line" 
      />

      <div>
        {loading 
        ? 
        (
          <Skeleton />
        ) 
        : 
        authenticated 
        ? 
        (
          <div>
            {selectedSection === "user-profile" && (
              <ForYouProfile userInfo={userInfo} researchAreas={researchAreas} userActivity={userActivity} updateUserInfoFunction={updateUserInfo} IDFs={IDFs}/>
            )}

            {selectedSection === "state-of-the-art" && (
              <ForYouStateArt 
                researchAreas={researchAreas} 
                keyApplications={keyApplications} 
                inventorsApplications={inventorsApplications} 
                keyPlayers={keyPlayers} 
                getApplications={getApplications} 
                applicationsFromSearchHistory={applicationsFromSearchHistory}
                technologyStatistics={technologyStatistics}
                updateTechnologyStatisticsFunction={getTechnologyStatistics}
              />
            )}
            {selectedSection === "technologies" && (
              <ForYouTechnologies
                technologySummary={technologySummary}
                researchAreas={researchAreas}
              />
            )}
            {selectedSection==="your-organization" && (
              <ForYouOrganizations domain={userDomain}/>
            )}

            {selectedSection==="patent-status" && (
              <ForYouStatus/>
            )}

            {selectedSection==="report" && (
              <ForYouReport keyPlayers={keyPlayers}/>
            )}

            {selectedSection==="own-your-invention" && (
              <OwnYourInvention />
            )}

          </div>
        ) 
        : 
        (
          // Message for unauthenticated user
          <GoToSignin/>
        )}
      </div>

      <Row className="g-0">
        <Col xs="12">
          <Footer/>
        </Col>
      </Row>

    </div>
  )
}

export default ForYou;